import React from 'react';
import DocumentsAmico from '../icons/Documents-amico.svg';

const HeroSection = () => {
  const scrollToServices = (e) => {
    e.preventDefault();
    const servicesSection = document.getElementById('services-section');
    servicesSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="bg-[#083941] text-[#002A30] flex items-center">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid lg:grid-cols-11 items-center">
        <div className="mr-auto place-self-center lg:col-span-6">
          <h1 className="max-w-2xl text-white mb-10 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-5xl">
          Transform Your Documents into Insightful Content
          </h1>
          <p className="max-w-2xl mb-6 font-thin text-white lg:mb-10 md:text-base lg:text-lg">
            Document Intelligence Hub leverages Large Language Models (LLMs) and Machine Learning to transform how you process, analyse, and extract insights from your documents. Streamline your workflow and unlock the power of your data.
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
            <a href="#services-section" onClick={scrollToServices} className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-[#002A30] rounded-lg hover:bg-[#002A30] bg-[#5ce2e7] hover:text-white focus:ring-4 focus:ring-[#91DFDA]">
              Get started
              <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white border border-white rounded-lg hover:bg-[#002A30] hover:text-white focus:ring-4 focus:ring-[#91DFDA]">
              Learn more
            </a>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex justify-center">
          <img src={DocumentsAmico} alt="Document Intelligence Hub Illustration" className="max-w-full h-auto" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;