import React, { useState } from 'react';
import { Menu } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (e, path) => {
    e.preventDefault();
    if (location.pathname === '/') {
      const section = document.getElementById(path.replace('/#', ''));
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <div
              className="flex-shrink-0 relative cursor-pointer"
              onClick={(e) => handleNavigation(e, '/#hero-section')}
            >
              <img
                src="https://i.imghippo.com/files/ngS5I1724975363.png"
                alt="BSF Logo"
                className="h-8 w-auto"
              />
              <span className="absolute bottom-0 left-0 text-[0.73rem] text-[#002A30] whitespace-nowrap transform translate-y-full">
                Document Intelligence Hub
              </span>
            </div>
          </div>

          {/* Add Flex Container to Move Items to Right */}
          <div className="hidden md:flex ml-auto items-center space-x-2">
            <NavItem
              title="Services"
              href="/#services-section"
              onClick={(e) => handleNavigation(e, '/#services-section')}
            />
            {/* Remove the commented About Us NavItem */}
            <NavItem
              title="Request for Demo"
              href="/contact"
              onClick={(e) => handleNavigation(e, '/contact')}
            />
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-[#002A30] hover:text-[#91DFDA] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#91DFDA]"
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Items */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {/* Remove the commented About Us MobileNavItem */}
            <MobileNavItem
              title="Services"
              href="/#services-section"
              onClick={(e) => handleNavigation(e, '/#services-section')}
            />
            <MobileNavItem
              title="Request for Demo"
              href="/contact"
              onClick={(e) => handleNavigation(e, '/contact')}
            />
          </div>
        </div>
      )}
    </nav>
  );
};

const NavItem = ({ title, href, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className="text-[#002A30] hover:bg-[#91DFDA] hover:text-[#002A30] px-3 py-2 rounded-md text-sm font-medium"
  >
    {title}
  </a>
);

const MobileNavItem = ({ title, href, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className="text-[#002A30] hover:bg-[#91DFDA] hover:text-[#002A30] block px-3 py-2 rounded-md text-base font-medium"
  >
    {title}
  </a>
);

export default NavBar;