import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiUpload, FiDownload, FiTable } from 'react-icons/fi';

const TableExtractor = () => {
  const [file, setFile] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [currentStep, setCurrentStep] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isStreamFinished, setIsStreamFinished] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please upload a file first');
      return;
    }

    setIsLoading(true);
    setCurrentStep('');
    setIsStreamFinished(false);
    setIsExtracting(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('http://0.0.0.0:8081/api/tabel_extractor/create', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setSessionId(result.sessionId);
      startTableExtraction(result.sessionId);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file.');
      setIsExtracting(false);
    } finally {
      setIsLoading(false);
    }
  };

  const startTableExtraction = useCallback(async (sessionId) => {
    if (!sessionId) return;

    try {
      const response = await fetch('http://0.0.0.0:8081/api/tabel_extractor/extractor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({ sessionId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsStreamFinished(true);
          setIsExtracting(false);
          break;
        }

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        lines.forEach(line => {
          if (line.trim()) {
            try {
              const data = JSON.parse(line);
              if (data.status) {
                setCurrentStep(data.status);
              }
            } catch (e) {
              console.error('Error parsing line:', line, e);
            }
          }
        });
      }
    } catch (error) {
      console.error('Error during table extraction:', error);
      setIsExtracting(false);
    }
  }, []);

  const handleDownload = async () => {
    if (!sessionId) {
      alert('No session ID available. Please extract a table first.');
      return;
    }
  
    setIsDownloading(true);
    setDownloadError(null);
  
    try {
      const response = await fetch('http://0.0.0.0:8081/api/tabel_extractor/download', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'extracted_table.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error('Download failed:', e);
      setDownloadError(e.message);
    } finally {
      setIsDownloading(false);
    }
  };
  
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  return (
    <div className="bg-white text-[#002A30] flex h-[calc(100vh-85px)]">
      <div className="flex-1 overflow-hidden flex flex-col max-w-[75rem] mx-auto w-full px-4">
        <div className="flex justify-between items-center py-4">
          <h1 className="text-2xl font-bold">Table Extractor</h1>
        </div>

        <div className="mb-8">
          <label
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
              isDragging
                ? 'border-[#91DFDA] bg-[#91DFDA] bg-opacity-10'
                : 'border-[#002A30] hover:bg-gray-50'
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <FiUpload className="w-10 h-10 mb-3 text-[#002A30]" />
              <p className="mb-2 text-sm text-[#002A30]">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-[#002A30]">
                PDF file only
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept='.pdf'
              onChange={handleFileChange}
              disabled={isLoading || isExtracting}
            />
          </label>
        </div>

        <AnimatePresence>
          {file && (
            <motion.p 
              className="text-center mb-4 text-[#002A30]"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              Selected file: <span className="font-semibold">{file.name}</span>
            </motion.p>
          )}
        </AnimatePresence>

        <div className="flex justify-center">
          <motion.button
            className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleUpload}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading || isExtracting || !file}
          >
            <FiTable />
            <span>{isLoading || isExtracting ? 'Processing...' : 'Extract Table'}</span>
          </motion.button>
        </div>
        
        <AnimatePresence>
          {isExtracting && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Extraction Progress:</h2>
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#002A30]"></div>
              </div>
              <p className="mt-2 text-center text-[#002A30]">{currentStep || 'Initializing...'}</p>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isStreamFinished && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Table Extraction Complete</h2>
              <p className="text-center text-[#002A30] mb-4">Your table has been extracted successfully.</p>
              <div className="mt-4 flex justify-center">
                <motion.button
                  className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2"
                  onClick={handleDownload}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FiDownload />
                  <span>Download XLSX</span>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TableExtractor;