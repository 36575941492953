import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import FileUpload from '../components/graphMain/FileUpload';
import DataPreview from '../components/graphMain/DataPreview';
import QueryForm from '../components/graphMain/QueryForm';
import GraphDisplay from '../components/graphMain/GraphDisplay';

const GraphMain = () => {
  const [file, setFile] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [dataHead, setDataHead] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [error, setError] = useState(null);

  const handleReset = () => {
    setFile(null);
    setSessionId(null);
    setDataHead(null);
    setGraphData(null);
    setError(null);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-gray-800 text-gray-100 min-h-screen font-sans">
      <header className="py-6 px-4 sm:px-6 lg:px-8 bg-gray-800 shadow-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" className="text-2xl font-bold text-blue-400 hover:text-blue-300 transition-colors duration-300">
              Bank Services Hub
            </Link>
          </motion.div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1 
          className="text-4xl font-bold mb-8 text-center text-blue-300"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8}}
        >
          Generate Graphs from Your Data
        </motion.h1>

        {error && (
          <motion.div 
            className="mb-6 p-4 bg-red-600 rounded-lg shadow-lg"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </motion.div>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <FileUpload
            setFile={setFile}
            setSessionId={setSessionId}
            setDataHead={setDataHead}
            setError={setError}
          />
        </motion.div>

        {file && (
          <motion.p 
            className="text-center mb-6 text-blue-200"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            Selected file: <span className="font-semibold">{file.name}</span>
          </motion.p>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <DataPreview dataHead={dataHead} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <QueryForm
            sessionId={sessionId}
            setGraphData={setGraphData}
            setError={setError}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <GraphDisplay graphData={graphData} />
        </motion.div>

        {(sessionId || graphData) && (
          <motion.button
            onClick={handleReset}
            className="mt-8 w-full bg-gray-700 hover:bg-blue-500 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 shadow-lg flex items-center justify-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Start Over
          </motion.button>
        )}
      </main>

      <footer className="mt-12 py-6 px-4 sm:px-6 lg:px-8 bg-gray-800">
        <p className="text-center text-gray-400">
          © 2024 Bank Services Hub. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default GraphMain;