import React from 'react';

const DataPreview = ({ dataHead }) => {
  if (!dataHead) return null;

  return (
    <div className="mb-8 bg-gray-800 p-4 rounded-lg">
      <h2 className="text-xl font-semibold mb-2">Data Preview:</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-600">
          <thead>
            <tr>
              {Object.keys(dataHead[0]).map((key) => (
                <th key={key} className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-600">
            {dataHead.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, cellIndex) => (
                  <td key={cellIndex} className="px-4 py-2 whitespace-nowrap text-sm">
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataPreview;