import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiUpload, FiDownload, FiFileText, FiGlobe } from 'react-icons/fi';

const SummarizingNew = () => {
  const [file, setFile] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [isStreamFinished, setIsStreamFinished] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [language, setLanguage] = useState('english');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setSessionId(null); // Reset session ID when a new file is selected
  };

  const createSession = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await fetch('http://0.0.0.0:8081/api/sessions/create', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setSessionId(result.sessionId);
      return result.sessionId;
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file.');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const startSummarization = useCallback(async () => {
    if (!file) {
      alert('Please upload a file first');
      return;
    }

    setIsSummarizing(true);
    setIsStreamFinished(false);

    let currentSessionId = sessionId;
    if (!currentSessionId) {
      currentSessionId = await createSession();
      if (!currentSessionId) return;
    }

    try {
      const response = await fetch('http://0.0.0.0:8081/api/summarizer/summarize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({ sessionId: currentSessionId, language })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();

      while (true) {
        const { done } = await reader.read();
        if (done) {
          setIsStreamFinished(true);
          break;
        }
      }
    } catch (error) {
      console.error('Error during summarization:', error);
      alert('An error occurred during summarization.');
    } finally {
      setIsSummarizing(false);
    }
  }, [file, sessionId, language]);

  const handleDownload = async () => {
    if (!sessionId) {
      console.error('No session ID available');
      return;
    }

    try {
      const response = await fetch('http://0.0.0.0:8081/api/summarizer/download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({ sessionId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'summary.pdf';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();
      const pdfBlob = new Blob([blob], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setSessionId(null); // Reset session ID when a new file is dropped
    }
  };

  return (
    <div className="bg-white text-[#002A30] flex h-[calc(100vh-85px)]">
      <div className="flex-1 overflow-hidden flex flex-col max-w-[75rem] mx-auto w-full px-4">
        <div className="flex justify-between items-center py-4">
          <h1 className="text-2xl font-bold">Summarization</h1>
        </div>

        <div className="mb-8">
          <label
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
              isDragging
                ? 'border-[#91DFDA] bg-[#91DFDA] bg-opacity-10'
                : 'border-[#002A30] hover:bg-gray-50'
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <FiUpload className="w-10 h-10 mb-3 text-[#002A30]" />
              <p className="mb-2 text-sm text-[#002A30]">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-[#002A30]">
                PDF or text files only
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept='.pdf,.txt'
              onChange={handleFileChange}
              disabled={isLoading || isSummarizing}
            />
          </label>
        </div>

        <AnimatePresence>
          {file && (
            <motion.p 
              className="text-center mb-4 text-[#002A30]"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              Selected file: <span className="font-semibold">{file.name}</span>
            </motion.p>
          )}
        </AnimatePresence>

        <div className="flex justify-center space-x-4 mb-4">
          <button
            className={`px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 ${
              language === 'english'
                ? 'bg-[#002A30] text-white'
                : 'bg-white text-[#002A30] border border-[#002A30]'
            }`}
            onClick={() => setLanguage('english')}
          >
            <FiGlobe />
            <span>English</span>
          </button>
          <button
            className={`px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 ${
              language === 'arabic'
                ? 'bg-[#002A30] text-white'
                : 'bg-white text-[#002A30] border border-[#002A30]'
            }`}
            onClick={() => setLanguage('arabic')}
          >
            <FiGlobe />
            <span>Arabic</span>
          </button>
        </div>

        <div className="flex justify-center">
          <motion.button
            className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={startSummarization}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading || isSummarizing || !file}
          >
            <FiFileText />
            <span>{isLoading || isSummarizing ? 'Processing...' : 'Generate Summary'}</span>
          </motion.button>
        </div>
        
        <AnimatePresence>
          {(isLoading || isSummarizing) && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Processing...</h2>
              <p className="text-center text-[#002A30]">Please wait while we generate your summary.</p>
              <div className="mt-4 flex justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#002A30]"></div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isStreamFinished && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Summary Ready</h2>
              <p className="text-[#002A30] mb-4">Your summary has been generated successfully.</p>
              <div className="mt-4 flex justify-center">
                <motion.button
                  className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2"
                  onClick={handleDownload}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FiDownload />
                  <span>Download Summary (PDF)</span>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SummarizingNew;