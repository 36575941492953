import React from 'react';
import BarGraph from '../graphs/BarGraph';
import PieGraph from '../graphs/PieGraph';
import BubbleGraph from '../graphs/BubbleGraph';
import LineGraph from '../graphs/LineGraph';
import PolarAreaChart from '../graphs/PolarAreaChart';
import ScatterChart from '../graphs/ScatterChart';

const GraphDisplay = ({ graphData }) => {
  if (!graphData) return null;

  const renderGraph = () => {
    const commonProps = {
      data: graphData,
      options: {
        ...graphData.options,
        responsive: true,
        maintainAspectRatio: false,
      },
    };

    switch (graphData.graphType) {
      case 'bar':
        return <BarGraph {...commonProps} />;
      case 'pie':
        return <PieGraph {...commonProps} />;
      case 'bubble':
        return <BubbleGraph {...commonProps} />;
      case 'line':
        return <LineGraph {...commonProps} />;
      case 'polarArea':
        return <PolarAreaChart {...commonProps} />;
      case 'scatter':
        return <ScatterChart {...commonProps} />;
      default:
        return <p>Unsupported graph type: {graphData.graphType}</p>;
    }
  };

  return (
    <div className="mt-8 w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">Generated Graph:</h2>
      <div className="flex justify-center items-center">
        <div 
          className="bg-gray-800 p-4 rounded-lg "
          style={{
            width: '90vw',
            height: '80vh',
            maxWidth: '1200px',
            maxHeight: '800px',
          }}
        >
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            {renderGraph()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphDisplay;