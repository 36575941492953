import React from 'react';
import { Bubble } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const BubbleGraph = ({ data }) => {
  const chartData = {
    datasets: data.graphData.datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: `rgba(75, 192, 192, ${0.6 - index * 0.1})`,
      borderColor: `rgba(75, 192, 192, ${1 - index * 0.1})`,
      borderWidth: 1,
    })),
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: data.graphTitle,
        color: 'white',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'white',
        borderWidth: 1,
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 12,
        },
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const dataPoint = dataset.data[context.dataIndex];
            return `${dataset.label}: (${dataPoint.x}, ${dataPoint.y}, ${dataPoint.r})`;
          },
        },
      },
    },
  };

  return <Bubble data={chartData} options={options} />;
};

export default BubbleGraph;