import React from 'react';
import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import ServiceCard from '../components/ServiceCard';

const HomePage = () => {
  return (
    <> 
    <HeroSection/>
    <ServiceCard/>
    <footer className="mt-12 py-6 px-4 sm:px-6 lg:px-8 bg-[#083941]">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="text-center text-gray-400 mb-4 md:mb-0">
            © 2024 NEOXA PTY LTD. All rights reserved.
          </p>
          <div className="flex space-x-4">
            <a href="#" className="text-gray-400 hover:text-blue-300 transition-colors">Privacy Policy</a>
            <a href="#" className="text-gray-400 hover:text-blue-300 transition-colors">Terms of Service</a>
          </div>
        </div>
      </footer>
 </>

    
  );
};

export default HomePage;