import React from 'react';
import { motion } from 'framer-motion';
import { FiSend } from 'react-icons/fi';

const MessageInput = ({ input, setInput, onSendMessage, isUploading, disabled }) => (
  <form onSubmit={onSendMessage} className="flex space-x-2">
    <input
      type="text"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      className={`flex-1 p-2 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-[#91DFDA] ${
        disabled
          ? 'bg-gray-200 text-gray-500 placeholder-gray-400 cursor-not-allowed'
          : 'bg-gray-50 text-[#002A30] placeholder-gray-400'
      }`}
      placeholder={disabled ? "Upload documents to start chatting" : "Type your message..."}
      disabled={disabled || isUploading}
    />
    <motion.button 
      type="submit" 
      className={`px-4 py-2 rounded-full transition duration-300 text-sm focus:outline-none focus:ring-2 focus:ring-[#91DFDA] flex items-center space-x-2 ${
        disabled || isUploading
          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
          : 'bg-[#002A30] text-white hover:bg-[#91DFDA] hover:text-[#002A30]'
      }`}
      whileHover={{ scale: disabled || isUploading ? 1 : 1.05 }}
      whileTap={{ scale: disabled || isUploading ? 1 : 0.95 }}
      disabled={disabled || isUploading}
    >
      <FiSend />
      <span className="hidden sm:inline">{isUploading ? 'Uploading...' : 'Send'}</span>
    </motion.button>
  </form>
);

export default MessageInput;