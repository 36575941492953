import React, { useState, useCallback, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Loader from './Loader';

const EBITDADisplay = React.memo(({ data }) => {
  if (!Array.isArray(data) || data.length === 0) return null;
  return (
    <div>
      {data.map((item, index) => (
        <div key={index}>
          Year: {item.year}, Amount: {item.amount}
        </div>
      ))}
    </div>
  );
});

const CollapsibleSection = React.memo(({ title, children, isOpen, toggleOpen }) => {
  return (
    <div className="mb-4 border border-[#91DFDA] rounded-lg overflow-hidden">
      <button
        className="w-full flex justify-between items-center p-4 bg-[#002A30] text-white hover:bg-[#91DFDA] hover:text-[#002A30] transition duration-300"
        onClick={toggleOpen}
      >
        <span className="text-xl font-semibold">{title}</span>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
});

const DataPreview = React.memo(({ tableData, tableHealth, isLoading }) => {
  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const [isTableHealthOpen, setIsTableHealthOpen] = useState(true);

  const toggleSummary = useCallback(() => setIsSummaryOpen(prev => !prev), []);
  const toggleTableHealth = useCallback(() => setIsTableHealthOpen(prev => !prev), []);

  const downloadCSV = useCallback(() => {
    if (!tableData || tableData.length === 0) return;

    const headers = ['Metric', 'Value'];
    const csvContent = [
      headers.join(','),
      ...tableData.map(row => {
        const value = Array.isArray(row[1])
          ? row[1].map(item => `${item.year}:${item.amount}`).join('; ')
          : row[1];
        return `"${row[0]}","${value}"`;
      })
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data_preview.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [tableData]);

  const memoizedContent = useMemo(() => {
    const hasTableData = Array.isArray(tableData) && tableData.length > 0;
    const hasTableHealth = Array.isArray(tableHealth) && tableHealth.length > 0;

    if (!hasTableData && !hasTableHealth) return null;

    return (
      <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
        {hasTableHealth && (
          <CollapsibleSection
            key="table-health"
            title="Document Health"
            isOpen={isTableHealthOpen}
            toggleOpen={toggleTableHealth}
          >
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-[#91DFDA]">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-medium text-[#002A30] uppercase tracking-wider">Sections</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-[#002A30] uppercase tracking-wider">Section Data</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-[#002A30] uppercase tracking-wider">Consistency Across Documents</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#91DFDA]">
                  {tableHealth.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                      <td className="px-4 py-2 text-sm text-[#002A30]">{item[0]}</td>
                      <td className="px-4 py-2 text-sm text-[#002A30]">{item[1]}</td>
                      <td className="px-4 py-2 text-sm text-[#002A30]">{item[2]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CollapsibleSection>
        )}

        {hasTableData && (
          <CollapsibleSection
            key="data-summary"
            title="Data Preview"
            isOpen={isSummaryOpen}
            toggleOpen={toggleSummary}
          >
            <div className="flex justify-end mb-3">
              <button 
                onClick={downloadCSV}
                className="bg-[#002A30] hover:bg-[#91DFDA] hover:text-[#002A30] text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Download CSV
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-[#91DFDA]">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-medium text-[#002A30] uppercase tracking-wider">Metric</th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-[#002A30] uppercase tracking-wider">Value</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#91DFDA]">
                  {tableData.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                      <td className="px-4 py-2 text-sm text-[#002A30]">{item[0]}</td>
                      <td className="px-4 py-2 text-sm text-[#002A30] whitespace-pre-wrap">
                        {Array.isArray(item[1]) 
                          ? <EBITDADisplay data={item[1]} />
                          : item[1]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CollapsibleSection>
        )}
      </div>
    );
  }, [tableData, tableHealth, isSummaryOpen, isTableHealthOpen, toggleSummary, toggleTableHealth, downloadCSV]);

  if (isLoading) return <Loader />;

  return memoizedContent;
});

export default DataPreview;