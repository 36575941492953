import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import HomePage from './HomePage';
import SummarizingNew from './SummarizingNew';
import TableGenerator from './TableGenerator';
import TableExtractor from './TableExtractor';
import ChatWithYourData from './ChatWithYourData';
import GraphMain from './GraphMain';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/summarize" element={<SummarizingNew />} />
          <Route path="/generate" element={<TableGenerator />} />
          <Route path="/extract" element={<TableExtractor />} />
          <Route path="/chat" element={<ChatWithYourData />} />
          <Route path="/GraphMain" element={<GraphMain />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;