import React from 'react';
import { motion } from 'framer-motion';
import MarkdownRenderer from './MarkdownRenderer';
import { HiUser } from 'react-icons/hi';
import { RiRobot2Fill } from 'react-icons/ri';

const Message = ({ message, sessionId }) => {
  const isUser = message.sender === 'user';

  const handleDownload = async () => {
    try {
      const response = await fetch('http://0.0.0.0:8081/api/summarizer/download', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId }),
      });

      if (!response.ok) {
        throw new Error('Download failed');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'summary.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error during download:', error);
      alert('Failed to download the PDF. Please try again.');
    }
  };

  const renderContent = () => {
    if (message.text.toLowerCase().includes('download')) {
      return (
        <div>
          <MarkdownRenderer text={message.text} />
          <button
            onClick={handleDownload}
            className="mt-2 px-4 py-2 bg-[#91DFDA] text-[#002A30] rounded hover:bg-[#7BC8C4] transition-colors"
          >
            Download PDF
          </button>
        </div>
      );
    }
    return <MarkdownRenderer text={message.text} />;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className={`flex w-full border-b border-[#91DFDA] ${isUser ? 'bg-gray-50' : 'bg-white'}`}
    >
      <div className="flex flex-row gap-6 p-6 w-full max-w-5xl mx-auto">
        <div className="flex-shrink-0">
          <div className={`w-10 h-10 rounded-full flex items-center justify-center ${isUser ? 'bg-[#002A30]' : 'bg-[#91DFDA]'}`}>
            {isUser ? (
              <HiUser className="h-6 w-6 text-white" />
            ) : (
              <RiRobot2Fill className="h-6 w-6 text-[#002A30]" />
            )}
          </div>
        </div>
        <div className="flex-grow">
          <div className="text-base text-[#002A30]">
            {renderContent()}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Message;