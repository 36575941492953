import React, { useState } from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import { motion } from 'framer-motion';

const QueryForm = ({ sessionId, setGraphData, setError }) => {
  const [query, setQuery] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateGraph = async (event) => {
    event.preventDefault();
    if (!query.trim() || !sessionId) return;

    setIsGenerating(true);
    setError(null);

    try {
      const response = await fetch('http://0.0.0.0:8081/api/grapher/graph', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId, userQuery: query })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setGraphData(data.data);
      } else {
        throw new Error('Graph generation failed');
      }
    } catch (error) {
      console.error('Error generating graph:', error);
      setError(`Error: ${error.message}`);
    } finally {
      setIsGenerating(false);
    }
  };

  if (!sessionId) return null;

  return (
    <form onSubmit={handleGenerateGraph} className="mb-8">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter your graph query here..."
        className="w-full bg-gray-800 text-white p-3 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        disabled={isGenerating}
        aria-label="Graph query"
      />
      <motion.button 
        type="submit" 
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-6 rounded-lg transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isGenerating}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        animate={isGenerating ? { scale: [1, 1.05, 1] } : {}}
        transition={{ duration: 2, repeat: isGenerating ? Infinity : 0 }}
      >
        <div
          animate={{ rotate: isGenerating ? 360 : 0 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
        >
          <FiBarChart2 className="mr-2 text-xl" aria-hidden="true" />
        </div>
        {isGenerating ? 'Generating...' : 'Generate Graph'}
      </motion.button>
    </form>
  );
};

export default QueryForm;