import React from 'react';

const TypingIndicator = () => (
  <div className="flex justify-start">
    <div className="bg-gray-700 text-white p-2 rounded-lg shadow-md">
      <div className="flex space-x-1">
        <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce"></div>
        <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
        <div className="w-1.5 h-1.5 bg-blue-400 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
      </div>
    </div>
  </div>
);

export default TypingIndicator;