import React from 'react';
import { AnimatePresence } from 'framer-motion';
import Message from './Message';
import TypingIndicator from './TypingIndicator';
import DataPreview from './DataPreview';

const MessageList = ({ messages, isTyping, tableData, chatEndRef,sessionId }) => (
  <div className="p-4 space-y-4 min-h-full bg-white">
    {/* {tableData && <DataPreview tableData={tableData} />} */}
    <AnimatePresence>
      {messages.map((message, index) => (
        <Message key={index} message={message} sessionId={sessionId}/>
      ))}
    </AnimatePresence>
    {isTyping && <TypingIndicator />}
    <div ref={chatEndRef} />
  </div>
);

export default MessageList;