import React from 'react';

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-24">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#002A30]"></div>
      {/* <span className="ml-3 text-lg text-[#002A30]">Loading...</span> */}
    </div>
  );
};

export default Loader;