import React, { useState, useRef, useEffect } from 'react';
import MessageList from '../components/ChatWithYourData/MessageList';
import MessageInput from '../components/ChatWithYourData/MessageInput';
import DataPreview from '../components/ChatWithYourData/DataPreview';
import Loader from '../components/ChatWithYourData/Loader';
import { Upload, ChevronDown, ChevronUp } from 'lucide-react';

const ChatWithYourData = () => {
  const [files, setFiles] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [tableHealth, setTableHealth] = useState(null);
  const [loadingStage, setLoadingStage] = useState(null);
  const [isDataPreviewOpen, setIsDataPreviewOpen] = useState(true);
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);

  const toggleDataPreview = () => {
    setIsDataPreviewOpen(!isDataPreviewOpen);
  };

  useEffect(() => {
    let timer;
    if (isUploading) {
      setLoadingStage('uploading');
      timer = setTimeout(() => {
        setLoadingStage('analyzing');
        timer = setTimeout(() => {
          setLoadingStage('summarizing');
          timer = setTimeout(() => {
            setLoadingStage('generating');
          }, 7000);
        }, 7000);
      }, 4000);
    }
    return () => clearTimeout(timer);
  }, [isUploading]);

  const handleFileUpload = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const uploadedFiles = Array.from(event.target.files);
      setFiles(uploadedFiles);
      setIsUploading(true);

      const formData = new FormData();
      uploadedFiles.forEach((file) => {
        formData.append('files', file);
      });

      try {
        const response = await fetch('http://0.0.0.0:8081/api/create/chat', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('File upload failed');
        }

        const data = await response.json();
        console.log(data);
        setSessionId(data.sessionId);
        if (data.tableData) {
          setTableData(data.tableData);
        }
        if (data.tableHealth) {
          setTableHealth(data.tableHealth);
        }
        const fileNames = uploadedFiles.map(file => file.name).join(', ');
        setMessages([{ text: `Files uploaded successfully: ${fileNames}. You can now start chatting with your data!`, sender: 'system' }]);
        setDocumentsUploaded(true);
      } catch (error) {
        console.error('Error uploading files:', error);
        setMessages([{ text: `Error uploading files: ${error.message}`, sender: 'system' }]);
      } finally {
        setIsUploading(false);
        setLoadingStage(null);
      }
    } else {
      console.error('No files selected');
      setMessages([{ text: 'Error: No files selected', sender: 'system' }]);
    }
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (!input.trim() || !sessionId) return;

    const userMessage = { text: input, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
    setIsDataPreviewOpen(false);  // Close the data preview when sending a message

    try {
      const response = await fetch('http://0.0.0.0:8081/api/rag/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          sessionId: sessionId,
          query: input
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      setMessages(prevMessages => [...prevMessages, { text: '', sender: 'bot' }]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const data = line.slice(5).trim();
            const match = data.match(/data='([^']*)'/);
            if (match) {
              const content = match[1];
              accumulatedResponse += content;

              setMessages(prevMessages => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = { 
                  text: accumulatedResponse.replace(/\\n/g, '\n'), 
                  sender: 'bot' 
                };
                return newMessages;
              });
            }
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, { text: `Error: ${error.message}`, sender: 'system' }]);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white text-[#002A30]">
      <div className="flex-shrink-0 p-4 border-b border-[#91DFDA]">
        <div className="max-w-[75rem] mx-auto w-full flex justify-between items-center">
          <h1 className="text-2xl font-bold">Credit AI analyst</h1>
          <div className="flex items-center">
            <label htmlFor="file-upload" className="cursor-pointer bg-[#002A30] text-white px-4 py-2 rounded-full hover:bg-[#91DFDA] hover:text-[#002A30] transition duration-300 flex items-center space-x-2">
              <Upload size={18} />
              <span>{isUploading ? 'Uploading...' : 'Upload Files'}</span>
            </label>
            <input
              id="file-upload"
              ref={fileInputRef}
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              multiple
              disabled={isUploading}
            />
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-hidden flex flex-col">
        <div className="flex-grow overflow-hidden max-w-[75rem] mx-auto w-full px-4 py-4 flex flex-col">
          {loadingStage ? (
            <div className="flex items-center justify-center h-64">
              <Loader />
              <p className="ml-4 text-lg font-semibold">
                {loadingStage === 'uploading' && 'Uploading files...'}
                {loadingStage === 'analyzing' && 'Analyzing documents...'}
                {loadingStage === 'summarizing' && 'Generating Data Preivew...'}
                {loadingStage === 'generating' && 'Generating Consistency Report...'}
              </p>
            </div>
          ) : (
            <>
              {(tableData || tableHealth) && (
                <div className={`mb-4 flex-shrink-0 transition-all duration-300 ${isDataPreviewOpen ? 'h-[70vh]' : 'h-10'}`}>
                  <div 
                    className="flex items-center justify-between cursor-pointer p-2 bg-gray-100 rounded-lg"
                    onClick={toggleDataPreview}
                  >
                    <span className="font-semibold">Data Preview</span>
                    {isDataPreviewOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                  </div>
                  <div className={`overflow-hidden transition-all duration-300 ${isDataPreviewOpen ? 'h-[calc(100%-2.5rem)]' : 'h-0'}`}>
                    <div className="h-full border border-gray-200 rounded-lg mt-2 overflow-y-auto">
                      <DataPreview 
                        tableData={tableData} 
                        tableHealth={tableHealth}
                        isLoading={isUploading}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex-grow overflow-y-auto bg-gray-50 rounded-lg shadow-lg">
                <MessageList 
                  messages={messages} 
                  isTyping={isTyping} 
                  tableData={tableData}
                  chatEndRef={chatEndRef}
                  sessionId={sessionId}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex-shrink-0 bg-white p-4 border-t border-[#91DFDA]">
        <div className="max-w-[75rem] mx-auto">
          <MessageInput
            input={input}
            setInput={setInput}
            onSendMessage={handleSendMessage}
            isUploading={isUploading}
            disabled={!sessionId}
          />
        </div>
      </div>

      <style jsx global>{`
        .markdown-content {
          font-size: 0.9rem;
          line-height: 1.6;
        }
        .markdown-content p {
          margin-bottom: 0.75em;
        }
        .markdown-content p:last-child {
          margin-bottom: 0;
        }
        .markdown-content ul, .markdown-content ol {
          margin-left: 1.5em;
          margin-bottom: 0.75em;
        }
        .markdown-content li {
          margin-bottom: 0.25em;
        }
        .markdown-content a {
          color: #91DFDA;
          text-decoration: underline;
        }
        .markdown-content code {
          background-color: #f1f5f9;
          padding: 0.2em 0.4em;
          border-radius: 0.3em;
          font-size: 0.85em;
        }
        .markdown-content pre {
          background-color: #f1f5f9;
          padding: 1em;
          border-radius: 0.5em;
          overflow-x: auto;
          margin-bottom: 0.75em;
        }
        .markdown-content blockquote {
          border-left: 4px solid #91DFDA;
          padding-left: 1em;
          margin-left: 0;
          margin-right: 0;
          font-style: italic;
        }
      `}</style>
    </div>
  );
};

export default ChatWithYourData;