import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiUpload, FiDownload, FiTable } from 'react-icons/fi';

const TableGenerator = () => {
  const [file, setFile] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isStreamFinished, setIsStreamFinished] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please upload a file first');
      return;
    }

    setIsLoading(true);
    setProgress(0);
    setIsStreamFinished(false);
    setIsGenerating(true);

    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await fetch('http://0.0.0.0:8081/api/sessions/create', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setSessionId(result.sessionId);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file.');
      setIsGenerating(false);
    } finally {
      setIsLoading(false);
    }
  };

  const parseEventData = (data) => {
    const match = data.match(/data=([\d.]+)/);
    return match ? parseFloat(match[1]) : null;
  };

  const startTableGeneration = useCallback(async () => {
    if (!sessionId) return;

    try {
      const response = await fetch('http://0.0.0.0:8081/api/tabel/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({ sessionId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsStreamFinished(true);
          setIsGenerating(false);
          break;
        }

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        lines.forEach(line => {
          if (line.startsWith('data:')) {
            const progressValue = parseEventData(line);
            if (progressValue !== null) {
              setProgress(progressValue);
            }
          }
        });
      }
    } catch (error) {
      console.error('Error during table generation:', error);
      setIsGenerating(false);
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionId) {
      startTableGeneration();
    }
  }, [sessionId, startTableGeneration]);

  const handleDownload = async () => {
    if (!sessionId) {
      console.error('No session ID available');
      return;
    }

    try {
      const response = await fetch('http://0.0.0.0:8081/api/tabel/download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({ sessionId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'table.pdf';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();
      
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  return (
    <div className="bg-white text-[#002A30] flex h-[calc(100vh-85px)]">
      <div className="flex-1 overflow-hidden flex flex-col max-w-[75rem] mx-auto w-full px-4">
        <div className="flex justify-between items-center py-4">
          <h1 className="text-2xl font-bold">Table Insights Generator</h1>
        </div>

        <div className="mb-8">
          <label
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
              isDragging
                ? 'border-[#91DFDA] bg-[#91DFDA] bg-opacity-10'
                : 'border-[#002A30] hover:bg-gray-50'
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <FiUpload className="w-10 h-10 mb-3 text-[#002A30]" />
              <p className="mb-2 text-sm text-[#002A30]">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-[#002A30]">
                PDF files only
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept='.pdf'
              onChange={handleFileChange}
              disabled={isLoading}
            />
          </label>
        </div>

        <AnimatePresence>
          {file && (
            <motion.p 
              className="text-center mb-4 text-[#002A30]"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              Selected file: <span className="font-semibold">{file.name}</span>
            </motion.p>
          )}
        </AnimatePresence>

        <div className="flex justify-center">
          <motion.button
            className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleUpload}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading || !file}
          >
            <FiTable />
            <span>{isLoading ? 'Processing...' : 'Generate Table'}</span>
          </motion.button>
        </div>
        
        <AnimatePresence>
          {isGenerating && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Table Generation Progress:</h2>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-2">
                <div 
                  className="bg-[#91DFDA] h-2.5 rounded-full transition-all duration-300 ease-in-out" 
                  style={{width: `${progress}%`}}
                ></div>
              </div>
              <p className="text-center text-[#002A30]">{Math.round(progress)}% Complete</p>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isStreamFinished && (
            <motion.div 
              className="mt-8 p-4 bg-gray-50 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-2 text-[#002A30]">Table Generation Complete</h2>
              <p className="text-center text-[#002A30]">Your table has been generated successfully.</p>
              <div className="mt-4 flex justify-center">
                <motion.button
                  className="px-6 py-2 bg-[#002A30] text-white rounded-lg hover:bg-[#91DFDA] hover:text-[#002A30] transition-colors flex items-center space-x-2"
                  onClick={handleDownload}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FiDownload />
                  <span>Download Table</span>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TableGenerator;