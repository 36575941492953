import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Summarizer from '../icons/Summarizer.svg';
import chat from '../icons/Ai_chat.svg';
import Generation from '../icons/Generation.svg';
import Extractor from '../icons/Extractor.svg';

const ServiceCard = ({ title, descriptionItems, image, delay, link, ctaText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, delay);
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [delay]);

  return (
    <div 
      ref={cardRef}
      className={`max-w-sm bg-white border border-[#91DFDA] rounded-lg shadow transition-all duration-500 ease-in-out h-[600px] flex flex-col ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <div className="h-41 overflow-hidden my-4">
        <img className="w-full h-full object-cover object-center rounded-t-lg " src={image} alt={title} />
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h5 className="mb-2 text-xl font-bold tracking-tight text-[#002A30]">{title}</h5>
        {/* Itemized Description */}
        <ul className="list-disc list-inside text-[#002A30] flex-grow">
          {descriptionItems.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
        <Link 
          to={link} 
          className="flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-[#002A30] rounded-lg hover:bg-[#002A30] bg-[#5ce2e7] hover:text-white focus:ring-4 focus:outline-none focus:ring-[#5ce2e7] mt-auto group"
        >
          {ctaText}
          <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2 transition-transform group-hover:translate-x-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const ServiceCards = () => {
  const services = [
    {
      title: "AI Assistant",
      descriptionItems: [
        "Upload multiple documents",
        "Chat with the AI Assistant and ask questions",
        "Quickly get insights from your files"
      ],
      image: chat,
      link: "/chat",
      ctaText: "Start Chatting"
    },
    {
      title: "Insights Generator",
      descriptionItems: [
        "Upload a document",
        "Instantly generate a predefined table",
        "Extract insights directly from content"
      ],
      image: Generation,
      link: "/generate",
      ctaText: "Generate Tables"
    },
    {
      title: "Table Extractor",
      descriptionItems: [
        "Upload a document containing tables",
        "Receive an Excel-ready version",
        "Transform complex data into spreadsheets"
      ],
      image: Extractor,
      link: "/extract",
      ctaText: "Extract Now"
    },
  ];

  return (
    <section id="services-section" className="py-16 mt-[-4rem] bg-white">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-[#002A30] mb-8">Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard 
              key={index} 
              {...service} 
              delay={index * 200}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceCards;