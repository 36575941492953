import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FiUpload, FiFile, FiCheck } from 'react-icons/fi';
import Papa from 'papaparse';
import { motion, AnimatePresence } from 'framer-motion';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ setFile, setSessionId, setDataHead, setError }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const fileUploadRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload({ target: { files: acceptedFiles } });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: '.csv',
    multiple: false
  });

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setIsUploading(true);
    setError(null);
    setUploadProgress(0);
    setUploadComplete(false);

    const formData = new FormData();
    formData.append('file', uploadedFile);

    try {
      Papa.parse(uploadedFile, {
        preview: 5,
        header: true,
        complete: (results) => {
          setDataHead(results.data);
          setTimeout(() => {
            const yOffset = -window.innerHeight / 4;
            const y = fileUploadRef.current.getBoundingClientRect().bottom + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }, 400);
        },
        error: (error) => {
          throw new Error(`CSV parsing error: ${error}`);
        }
      });

      // Simulate upload progress
      const interval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prevProgress + 10;
        });
      }, 500);

      const response = await fetch('http://0.0.0.0:8081/api/sessions/create', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const data = await response.json();
      setSessionId(data.sessionId);
      
      setUploadProgress(100);
      setUploadComplete(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError(`Error: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <motion.div 
      ref={fileUploadRef}
      className="flex items-center justify-center w-full mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        {...getRootProps()}
        className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer transition-all duration-300 ${
          isDragActive || isDragging
            ? 'border-blue-500 bg-blue-100 dark:bg-blue-900'
            : 'border-gray-600 bg-gray-800 hover:bg-gray-700'
        }`}
      >
        <input {...getInputProps()} disabled={isUploading} />
        <AnimatePresence>
          {!isUploading ? (
            <motion.div 
              className="flex flex-col items-center justify-center pt-5 pb-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div
                animate={{ rotate: isDragActive || isDragging ? 360 : 0 }}
                transition={{ duration: 0.5 }}
              >
                <FiUpload className="w-12 h-12 mb-4 text-gray-400" />
              </motion.div>
              <motion.p 
                className="mb-2 text-lg text-gray-400"
                animate={{ scale: isDragActive || isDragging ? 1.1 : 1 }}
              >
                <span className="font-semibold">Click to upload</span> or drag and drop
              </motion.p>
              <p className="text-sm text-gray-400">
                CSV files only
              </p>
            </motion.div>
          ) : (
            <motion.div 
              className="flex flex-col items-center justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <FiFile className="w-12 h-12 mb-4 text-blue-500" />
              <p className="text-lg text-blue-500">Uploading...</p>
              <div className="w-64 h-2 bg-gray-700 rounded-full mt-4 overflow-hidden">
                <motion.div
                  className="h-full bg-blue-500"
                  initial={{ width: 0 }}
                  animate={{ width: `${uploadProgress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {uploadComplete && (
          <motion.div
            className="absolute mt-4 flex items-center justify-center text-green-500"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.3 }}
          >
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FileUpload;