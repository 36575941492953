import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownRenderer = ({ text }) => (
  <ReactMarkdown
    components={{
      h1: ({ node, children, ...props }) => <h1 className="text-2xl font-bold mb-2 mt-4 text-[#002A30] border-b-2 border-[#91DFDA] pb-2" {...props}>{children}</h1>,
      h2: ({ node, children, ...props }) => <h2 className="text-xl font-semibold mb-2 mt-3 text-[#002A30]" {...props}>{children}</h2>,
      h3: ({ node, children, ...props }) => <h3 className="text-lg font-medium mb-2 mt-3 text-[#002A30]" {...props}>{children}</h3>,
      h4: ({ node, children, ...props }) => <h4 className="text-base font-medium mb-2 mt-2 text-[#002A30]" {...props}>{children}</h4>,
      h5: ({ node, children, ...props }) => <h5 className="text-sm font-medium mb-1 mt-2 text-[#002A30]" {...props}>{children}</h5>,
      h6: ({ node, children, ...props }) => <h6 className="text-xs font-medium mb-1 mt-2 text-[#002A30]" {...props}>{children}</h6>,
      p: ({ node, children, ...props }) => <p className="mb-2 text-[#002A30]" {...props}>{children}</p>,
      a: ({ node, children, ...props }) => <a className="text-[#91DFDA] hover:underline" {...props}>{children}</a>,
      ul: ({ node, children, ...props }) => <ul className="list-disc list-inside mb-2" {...props}>{children}</ul>,
      ol: ({ node, children, ...props }) => <ol className="list-decimal list-inside mb-2" {...props}>{children}</ol>,
      li: ({ node, children, ...props }) => <li className="mb-1 text-[#002A30]" {...props}>{children}</li>,
      blockquote: ({ node, children, ...props }) => <blockquote className="border-l-4 border-[#91DFDA] pl-4 italic my-2 text-[#002A30]" {...props}>{children}</blockquote>,
      code: ({ node, inline, children, ...props }) => 
        inline 
          ? <code className="bg-gray-100 rounded px-1 py-0.5 text-[#002A30]" {...props}>{children}</code>
          : <code className="block bg-gray-100 rounded p-2 my-2 text-[#002A30] whitespace-pre-wrap" {...props}>{children}</code>,
    }}
  >
    {text}
  </ReactMarkdown>
);

export default MarkdownRenderer;